<template>
    <div>
        <v-form ref="search">
            <search-area title="Refine Your Search" height="80">
                <template v-slot:item>
                    <v-text-field class="search-input" v-model="search_form.location"
                                  outlined
                                  label="Search neighbourhood, city or state"
                    >
                        <template v-slot:prepend-inner>
                            <div class="prepend-div ">
                                <v-icon size="25" color="primary">mdi-magnify</v-icon>
                            </div>

                        </template>
                    </v-text-field>
                </template>
            </search-area>
<!--            <v-card color="white" elevation="1" height="100">-->
<!--                <v-card-text>-->
<!--                    <v-text-field class="search-input" v-model="search_form.location"-->
<!--                            outlined-->
<!--                            label="Search neighbourhood, city or state"-->
<!--                    >-->
<!--                        <template v-slot:prepend-inner>-->
<!--                            <div class="prepend-div ">-->
<!--                                <v-icon size="25" color="primary">mdi-magnify</v-icon>-->
<!--                            </div>-->

<!--                        </template>-->
<!--                    </v-text-field>-->
<!--                </v-card-text>-->
<!--            </v-card>-->
<!--            <div class="search-title">-->
<!--                <p>Refine Your Search</p>-->
<!--            </div>-->
<!--            <v-divider></v-divider>-->

            <v-container>
                <search-section-title title="Property type" :container="false"></search-section-title>
                <div class="radios">
                    <span v-for="(r,i) in property_type" :key="i">
                         <input type="radio" name="rGroup" v-model="search_form.property_type"  :value="r" :id="i"  />
                            <label class="radio text-center" :for="i" style="width: 60px;height: 60px">
                                <span >{{r}}</span>
                            </label>
                    </span>
                </div>
            </v-container>
            <v-divider></v-divider>
            <v-container>
                <div class="bed-bath-section">
                    <div class="bed  bed-bath-section-child">

                        <p class="mt-1">Bed(s)</p>
                        <v-select v-model="search_form.bedroom"
                                :items="items"
                                filled
                                dense
                                label="Any"
                        >
                            <template v-slot:append>
                                <div class="select-icon">
                                    <v-icon color="primary">mdi-chevron-down</v-icon>
                                </div>

                            </template>
                        </v-select>
                    </div>
                    <div class="bath bed-bath-section-child">
                        <p class="mt-1">Bath(s)</p>
                        <v-select v-model="search_form.bathroom"
                                :items="items"
                                filled
                                dense
                                label="Any"

                        >
                            <template v-slot:append>
                                <div class="select-icon">
                                    <v-icon color="primary">mdi-chevron-down</v-icon>
                                </div>

                            </template>
                        </v-select>
                    </div>
                </div>
            </v-container>
            <v-divider></v-divider>
            <v-container>
                <search-section-title title="Maximum Price" :container="false"></search-section-title>
                <div class="price_div">

                    <v-range-slider
                            v-model="range"
                            :max="max"
                            :min="min"
                            :step="step"
                            hide-details

                    >
<!--                        <template v-slot:prepend>-->
<!--                            <span>min</span>-->

<!--                        </template>-->
<!--                        <template v-slot:append>-->
<!--                            <span>max</span>-->
<!--                        </template>-->
                    </v-range-slider>
                    <div class="text-center show_price">
                        <span>min</span>
                        <div class="price_detail">
                            <span>{{range[0] | easy_currency}}   -   {{range[1] |easy_currency}}</span>
                        </div>
                        <span>max</span>
                    </div>
                </div>

            </v-container>
            <v-container>
                <v-btn
                        type="submit"
                        :loading="apiloading"
                        :disabled="apiloading"
                        block
                        color="secondary"
                        class="search-btn"
                        @click.prevent="searchProperty()"
                >Find Property</v-btn>
            </v-container>



        </v-form>

        <v-container>

        </v-container>
    </div>
</template>

<script>
    import {loadingMixin} from "../../../mixins/Loading";

    export default {
        inject:["Bus"],
        name: "SearchPage",
        mixins:[loadingMixin],
        data() {
            return {
                property_type:[
                    "Any","Bungalow","Duplex","Flat","Maisonette","Semi-Detached","Terrace","Serviced Plots"
                ],
                items:[1,2,3,4,5],
                min: 1000000,
                max: 100000000,
                step:500000,
                slider: 40,
                range: [10000000, 50000000],
                search_form:{
                    location:'',
                    property_type:'',
                    bedroom:'',
                    bathroom:'',
                    price:[10000000, 50000000]
                }
            }
        },
        watch: {
            range:function(newValue, oldValue) {
                this.search_form.price = newValue;
            }
        },
        methods: {
            searchProperty() {
                console.log(this.search_form);

            }
        },
        created() {
            this.Bus.$on("search-property",(s)=>{
                console.log(s);
                this.search_form = s;
            })
            this.Bus.$on("search-input",(s)=>{
                console.log("search_input",s);
                this.search_form.location = s;
            })
        }
    }
</script>

<style scoped>
    .v-text-field.v-text-field--enclosed {
        margin: 10px;
        padding: 0;
        /*border-radius: 25px;*/
    }
    .search-input{
        border-radius: 25px;
    }


    .prepend-div{
        background: rgba(0, 117, 242, 0.05);
        text-align: center;
        width: 35px;
        height: 35px;
        border-radius: 50%;
        padding: 5px;
        margin-top: -5px;
    }
    .property-type-title{
        padding:5px ;
        margin-top:-15px;
        color:#379392;
    }
    .property-type-title p{
        /*font-size: 13px;*/
    }

    .bed-bath-section{
        display: flex;
        margin-top:-15px;

    }
    .bed-bath-section-child{
        width:50%;
        margin:0 10px;
    }
    .bed-bath-section-child p{
        color:#379392;
        padding-top:2px;
    }

    .v-input__slot{
        margin-bottom: 0 !important;
    }
    .v-text-field--filled {
        border-right: 10px !important;
        border-radius: 12px !important;
    }
    .theme--light.v-text-field > .v-input__control > .v-input__slot:before {
         border-color:#fff !important;
    }
    .v-text-field > .v-input__control > .v-input__slot:after {
        border-color:#fff !important;
        border-style:none !important;
        border-width: thin 0 thin 0;
        -webkit-transform: scaleX(0);
        transform: scaleX(0);
    }
    .v-text-field > .v-input__control > .v-input__slot:before {
        border-style:none !important;
        border-color:#fff !important;
        border-width: thin 0 0 0;
    }
    .price_div{
        width: 100%;
        padding:0 10px;
    }
    .show_price{
        display: flex;
        justify-content: space-around;
    }
    .show_price span{
        width:10%;
        color:#9B9B9B;
    }
    .show_price .price_detail{
        width:80%;
        display: flex;
        justify-content: center;

    }
    .show_price .price_detail span{
        margin:0 10px;
        width:100%;
        color:#4A4A4A;
    }
    /*.v-btn::before{*/
    /*    color:#fff;*/
    /*}*/
    .search-btn{
        color: #fff !important;
        height: 44px !important;
        margin: 0 auto;
    }

</style>